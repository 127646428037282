<template>
    <div>
        <div class="panel-style">
            <el-form :inline="true" :model="searchDto" size="mini">
                <el-form-item label="产品线名称">
                    <el-input v-model="searchDto.name" clearable autocomplete="off" placeholder="产品线名称"/>
                </el-form-item>
                <el-form-item label="产品线编码">
                    <el-input v-model="searchDto.code" clearable autocomplete="off" placeholder="产品线编码"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQueryClickEvent">查询</el-button>
                    <el-button type="primary" @click="openProductLineDetailPage(null)">新增</el-button>
                </el-form-item>
            </el-form>
            <el-table border stripe size="small" :data="tableData" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                      max-height="550px" v-loading="listLoading">
                <el-table-column type="index" label="序号" align="left" width="80px"/>
                <el-table-column prop="name" label="产品线名称" align="left"/>
                <el-table-column prop="code" label="产品线编码" align="left"/>
                <el-table-column prop="updateTime" label="更新时间" align="center" width="150px"/>
                <el-table-column label="操作" align="center" width="80">
                    <template v-slot="scope">
                        <div class="option-btn-div">
                            <el-tooltip v-delTabIndex effect="dark" content="编辑" placement="top">
                                <i class="el-icon-edit option-btn-normal" @click="openProductLineDetailPage(scope.row.id)"/>
                            </el-tooltip>
                            <el-tooltip v-delTabIndex effect="dark" content="删除" placement="top">
                                <i class="el-icon-delete option-btn-normal" style="color: #F56C6C" @click="removeProductLine(scope.row)"/>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableTotal"
                        :current-page.sync="searchDto.pageNum"
                        :page-sizes="[10, 50, 100]"
                        :page-size="searchDto.pageSize"
                        @size-change="handleSizeChangeEvent"
                        @current-change="handleCurrentChangeEvent"/>
            </div>
            <product-line-detail ref="productLineDetailPage" @queryProductLineList="queryProductLineList"/>
        </div>
    </div>
</template>

<script>
import message from '@/utils/message';
import {queryProductLineList, removeProductLine} from '@/api/sys/product/productLineApi';
import ProductLineDetail from '@/views/sys/product-line/product-line-detail';
import global from '@/components/Global';
export default {

    name: 'ProductLineList',
    components: {

        ProductLineDetail
    },
    data() {

        return {

            searchDto: {

                pageNum: 1,
                pageSize: 10,
                id: '',
                name: null,
                code: '',
            },
            listLoading:false,
            tableData: [],
            tableTotal: 0,
            detailDialogVisible: false
        }
    },
    methods: {

        initProductLineListPage() {

            this.queryProductLineList();
        },
        handleCurrentChangeEvent(val) {

            this.searchDto.pageNum = val;
            this.queryProductLineList();
        },
        handleSizeChangeEvent(val) {

            this.searchDto.pageSize = val;
            this.queryProductLineList();
        },
        handleQueryClickEvent() {

            this.searchDto.pageNum = 1;
            this.queryProductLineList();
        },
        queryProductLineList() {

            this.listLoading = true;
            queryProductLineList(this.searchDto).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
                this.listLoading = false;
            });
        },
        openProductLineDetailPage(id) {

            this.$refs.productLineDetailPage.initProductLineDetailPage(id);
        },
        removeProductLine(row) {

            this.$confirm('是否需要删除？', '提示', {type: 'warning'})
                .then(() => {

                    removeProductLine({id : row.id}).then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            message.success('删除成功');
                        } else {

                            message.error(res.msg);
                        }
                        this.queryProductLineList();
                    }).catch(err => {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                });
        },
    },
    mounted() {

        this.initProductLineListPage();
    }
}
</script>

<style lang="scss" scoped>

.pagination {

    margin-top: 15px;
    text-align: right;
}
.option-btn-div {

    display: flex;
    justify-content: space-around
}
.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
</style>
