<template>
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" top="0"
           @close="closeProductLineInfoDetailPage" width="650px" :close-on-click-modal="false" center>
        <el-form :model="productLineDto" :rules="formRules" ref="productLineForm" size="mini" label-width="120px">
            <el-row type="flex" justify="center">
                <el-form-item label="产品线名称" prop="name">
                    <el-input v-model="productLineDto.name" autocomplete="off" maxlength="32" class="line-input"/>
                </el-form-item>
            </el-row>
            <el-row type="flex" justify="center">
                <el-form-item label="产品线编码" prop="code">
                    <el-input :disabled="productLineDto.id !== null" v-model="productLineDto.code"
                          autocomplete="off" maxlength="32" class="line-input"/>
                </el-form-item>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveOrModifyProductLine" size="mini">确定</el-button>
            <el-button @click="detailDialogVisible = false" size="mini">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import message from '@/utils/message';
import {queryProductLineById, saveOrModifyProductLine} from '@/api/sys/product/productLineApi';
import global from '@/components/Global';
import loading from '@/utils/cmdLoading';

export default {

    name: 'ProductLineDetail',
    data() {

        return {

            detailDialogVisible: false,
            detailDialogTitle: '',
            jsonSchema: '',
            productLineDto: {

                id: null,
                name: null,
                code: null
            },
            formRules: {

                name: [
                    {required: true, message: '请输入产品线名称', trigger: 'blur'},
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur'},
                    { pattern: /^\S*$/, message: '注意空格', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '请输入产品线编码', trigger: 'blur'},
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur'},
                    { pattern: /^[0-9a-zA-Z_]{1,}$/, message: '只能为数字、英文字母和下划线', trigger: 'blur'}
                ],
            }
        };
    },
    methods: {

        initProductLineDetailPage(id) {

            let that = this;
            that.detailDialogVisible = true;
            that.detailDialogTitle = '新增';
            that.productLineDto.id = null;
            if (id !== null) {

                // 查询产品型号信息详情
                that.queryProductLineById(id);
                that.detailDialogTitle = '编辑';
                that.productLineDto.id = id;
            }
        },
        closeProductLineInfoDetailPage() {

            // 重置表单
            this.$refs['productLineForm'].resetFields();
        },
        queryProductLineById(id) {

            let that = this;
            let param = {

                id: id
            };
            let loadingCircle = loading.loading('加载中...', 30);
            queryProductLineById(param).then((res) => {

                loadingCircle.close();
                if (res.code === global.response_status_success_obj) {

                    that.productLineDto = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        saveOrModifyProductLine() {

            this.$refs['productLineForm'].validate((valid) => {

                if (valid) {

                    saveOrModifyProductLine(this.productLineDto).then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            this.detailDialogVisible = false;
                            this.$emit('queryProductLineList');
                            if (this.productLineDto.id === null) {

                                message.success('新增成功');
                            } else {

                                message.success('修改成功');
                            }
                        } else {

                            if (res.msg) {

                                message.error(res.msg);
                            }
                        }
                    }).catch(err => {

                        message.error('请求失败,请稍后再试');
                        console.error(err);
                    });
                } else {

                    return false;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.line-input {

    width: 180px;
}
</style>
