import axios from 'axios';
import global from '@/components/Global.vue';

let base = global.serverSite + '/doartIot/doart-system';

// 分页查询产品线信息
export const queryProductLineList = params => { return axios.post(base + '/productLine/queryProductLineList', params)};

// 添加/修改产品线信息
export const saveOrModifyProductLine = params => { return axios.post(base + '/productLine/saveOrModifyProductLine', params)};

// 查询产品线信息
export const queryProductLineById = params => { return axios.post(base + '/productLine/queryProductLineById', params)};

// 删除产品线信息
export const removeProductLine = params => { return axios.post(base + '/productLine/removeProductLine', params)};
